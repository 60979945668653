import { atom, DefaultValue, selector } from "recoil"
import clone from 'just-clone'

type Download = {
	id?: number,
	name?: string,
	protocols?: string[],
	regex?: RegExp,
	sha512?: string,
	size?: number,
	url?: string
}

const downloadData = [
  {
    id: 1,
    name: 'macOS (M1 or M2)',
    regex: /^.*arm64.dmg/gm,
    protocols: ['WhatsApp', 'iMessage'],
  },
  {
    id: 2,
    name: 'macOS (Intel)',
    regex: /^((?!arm64).)*.dmg/gm,
    protocols: ['WhatsApp', 'iMessage'],
  },
  {
    id: 3,
    name: 'Windows (64-bit)',
    regex: /^.*.exe/gm,
    protocols: ['WhatsApp'],
  },
]

const processDownloadData = (files: Download[]) => {
  return downloadData.map(d => {
		const foundFiles = files.filter(f => {
      const reg = clone(d.regex)
      return f.url ? !!f.url.match(reg) : false
    })
		return {
    ...d,
    ...foundFiles[0]
  	}
	}).filter(d => !!d.url)
}

export const downloadsState = atom({
  key: "downloadsState", 
  default: [] as Download[],
})

export const downloadVersion = atom({
  key: "downloadVersion",
  default: '' as string,
})

export const downloadSelector = selector({
	key: 'downloadSelector',
	get: (({get}) => get(downloadsState)),
	set: (({set}, newValue) => {
		if (newValue instanceof DefaultValue) return
    const processed = processDownloadData(newValue)
		set(downloadsState, processed)
	})
})