import fetch from "node-fetch"

export class FollowUpBoss {
  public apiKey: string
  public system: string
  public systemKey: string
  public headers: any

  constructor (apiKey: string) {
    this.apiKey = apiKey
    this.system = process.env.FUB_SYSTEM ?? ''
    this.systemKey = process.env.FUB_SYSTEM_KEY ?? ''
    this.headers = {
      accept: "application/json",
      authorization: `Basic ${Buffer.from(`${this.apiKey}:`).toString('base64')}`,
      "X-System": this.system,
      "X-System-Key": this.systemKey,
      "Content-Type": 'application/json',
    }
  }

  async isApiKeyValid() {
    const response = await fetch('https://api.followupboss.com/v1/identity', {
      headers: this.headers
    })
    return response.status === 200
  }

  async getData(endpoint: string) {
    const resp = await fetch(`https://api.followupboss.com/v1/${endpoint}`, {
      headers: this.headers
    })
    const respData = await resp.json()
    return respData
  }

  async postData(endpoint: string, data: unknown) {
    const resp = await fetch(`https://api.followupboss.com/v1/${endpoint}`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(data)
    })
    const respData = await resp.json()
    return respData
  }

  async putData(endpoint: string, data: unknown) {
    const resp = await fetch(`https://api.followupboss.com/v1/${endpoint}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(data)
    })
    const respData = await resp.json()
    return respData
  }

  async deleteData(endpoint: string) {
    const resp = await fetch(`https://api.followupboss.com/v1/${endpoint}`, {
      method: 'DELETE',
      headers: this.headers
    })
    const respData = await resp.json()
    return respData
  }

}