import { SupabaseClient } from '@supabase/supabase-js'
import fetch from 'node-fetch'
import { FollowUpBoss } from "./followUpBoss"
import { checkPhoneEmail } from "../checkPhone"
import { parseNote } from "../parseNote"
import { Database } from '../database.types'

type UserContactMapping = {
  user_id: string,
  contact: string,
  contact_id: string,
  integration_id: string,
  org_id?: string,
}

export const fubPeopleUpdated = async (integration: any, userId: string, data: any, supabaseAdmin: SupabaseClient<Database>, orgId?: string) => {
  const fub = new FollowUpBoss(integration.data?.api_key)

  // Check validity of API key
  const validity = await fub.isApiKeyValid()
  if (!validity) throw new Error(`${userId}'s FUB API Key ${integration.data?.api_key} is invalid`)

  // console.log('webhook data: ', data)
  // Pull Person Data
  const personId = data.resourceIds[0]
  const personData = await fub.getData(`people/${personId}`)
  const { emails, phones, tags } = personData
  if (!tags.includes('Automata')) {
    const deleteData = await fubPersonDeleted(integration, userId, data, supabaseAdmin)
    return deleteData
  }

  const upsert: UserContactMapping[] = []
  if (emails) {
    emails.forEach((email: any) => {
      if (email.status === 'Valid') {
        upsert.push({
          user_id: orgId ?? userId,
          contact: email.value,
          contact_id: personId,
          integration_id: integration.id
        })
      }
    })
  }
  if (phones) {
    phones.forEach((phone: any) => {
      if (phone.status === 'Valid' && !phone.isLandline) {
        const { phoneNumber, error } = checkPhoneEmail(phone.normalized, 'US')
        if (!error)
        upsert.push({
          user_id: orgId ?? userId,
          contact: phoneNumber,
          contact_id: personId,
          integration_id: integration.id
        })
      }
    })
  }
  // Post phone numbers and emails and contact id to mapping table
  if (upsert.length === 0) return { data: null, error: 'Nothing to upsert' }
  const { data: upsertData, error: upsertError } = await supabaseAdmin
  .from('user_contact_mapping')
  .upsert(upsert, { onConflict: 'user_id,contact,integration_id'})
  .select()
  // console.log('upsert: ', upsert)
  return { data: upsertData, error: upsertError }
  
}

export const fubPersonDeleted = async (integration: any, userId: string, data: any, supabaseAdmin: SupabaseClient<Database>, orgId?: string) => {
  const fub = new FollowUpBoss(integration.data?.api_key)

  // Check validity of API key
  const validity = await fub.isApiKeyValid()
  if (!validity) throw new Error(`${userId}'s FUB API Key ${integration.data?.api_key} is invalid`)

  // console.log('webhook data: ', data)
  // Delete Person Data, but only for tagged data
  const personId = data.resourceIds[0]
  const { error } = await supabaseAdmin
    .from('user_contact_mapping')
    .delete()
    .eq('contact_id', personId)
    .in('user_id', [orgId ?? userId, userId])
    .not('messaged', 'is', true)
  if (error) throw new Error(JSON.stringify(error))
  
  return { data: { status: `${userId}'s contact ${personId} has been deleted` }, error}
}

export const fubNoteCreated = async (integration: any, userId: string, data: any, supabaseAdmin: SupabaseClient<Database>) => {
  const fub = new FollowUpBoss(integration.data?.api_key)

  // Check validity of API key
  const validity = await fub.isApiKeyValid()
  if (!validity) throw new Error(`${userId}'s FUB API Key ${integration.data?.api_key} is invalid`)

  // Get note data based on resource ID
  const noteId = data.resourceIds[0]
  const noteData = await fub.getData(`notes/${noteId}`)
  const { personId, body: noteBody } = noteData
  if (!personId) throw new Error(`Person Id missing in note: ${JSON.stringify(noteData)}`)
  if (!noteBody) throw new Error(`Note Body missing in note: ${JSON.stringify(noteData)}`)

  // Get contact data based on person ID
  const personResponseBody = await fub.getData(`people/${personId}`)

  // Parse note body into: protocol, message, and phone label/email
  // TODO: Check how often Ian has multiple phone/email, how often do multiple phone/email have same label
  const { error: ParseError, protocol, label, count, message, schedule } = parseNote(noteBody)
  if (ParseError) return { data: null, error: ParseError }
  if (!protocol) return { data: null, error: 'Missing protocol' }

  // Parse contact data to obtain number/email
  let contact
  if (label) {
    contact = label === 'email' 
      ? personResponseBody.emails[count ? count - 1 : 0].value
      : personResponseBody.phones.filter((e: any) => e.type === label)[count ? count - 1 : 0]?.value
  } else {
    contact = personResponseBody.phones[0].value ?? personResponseBody.emails[0].value
  }
  if (!contact) throw new Error(`Contact not found. personId: ${personId}. Label: ${label}. Count: ${count}`)

  const { phoneNumber, error: checkError, isEmail } = checkPhoneEmail(contact.indexOf('@') < 0 && contact.indexOf('+') < 0 ? `+1${contact}`: contact)
  if (checkError) throw new Error(`Bad phone number: ${contact}`)

  // Log number/email <> contact id mapping
  const { error: error3 } = await supabaseAdmin.from('user_contact_mapping').upsert({ 
    user_id: userId,
    integration_id: integration.id,
    contact: phoneNumber,
    contact_id: personId,
    messaged: true
  }, { onConflict: 'user_id,contact,integration_id'})
  if (error3) throw new Error(`Log contact mapping failed: ${JSON.stringify(error3)}`)

  // Post message
  const { data: keyData, error: error4 } = await supabaseAdmin.from('keys').select('*').filter('status','eq','active').filter('user_id', 'eq', userId)
  if (error4 || !keyData[0].id) throw new Error(`Unable to get key for ${userId}`)
  const postMsg = await fetch(`https://api.getautomata.app/api/send/${keyData[0].id}/${protocol}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      "phoneNumber": phoneNumber, 
      message,
      integration: 'Follow Up Boss',
      schedule,
      noteId
    }),
  })
  const postMsgResult = await postMsg.json()
  if (postMsg.status !== 200) throw new Error('Message post failed')
  return { data: postMsgResult, error: null}
  
}