import '@picocss/pico'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs'
import { SessionContextProvider, Session } from '@supabase/auth-helpers-react'
import { useState, useEffect } from 'react'
import { RecoilRoot } from "recoil"
import { Database } from '@/lib/database.types'
import { RealtimeProvider } from '@/hooks'
import '../styles.css'
import { PostHogProvider} from 'posthog-js/react'
import posthog from 'posthog-js'

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Disable in development
    // loaded: (posthog) => {
    //   if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing()
    // }
  })
}

export default function App({ Component, pageProps }: AppProps<{
  initialSession: Session
}>) {

  const router = useRouter()
  const [supabaseClient] = useState(() => createBrowserSupabaseClient<Database>({
    options: {
      realtime: {
        params: {
          eventsPerSecond: 1,
        }
      }
    }
  }))

  useEffect(() => {
    const handleRouteChange = () => posthog.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <PostHogProvider 
      client={posthog}
    >
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <RecoilRoot>
          <RealtimeProvider>
            <Component {...pageProps} />
          </RealtimeProvider>
        </RecoilRoot>
      </SessionContextProvider>
    </PostHogProvider>
  )
}
