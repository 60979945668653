import { atom, DefaultValue, selector } from "recoil"
import { SupabaseClient } from '@supabase/auth-helpers-react'
import { Database } from '@/lib/database.types'

type MessagesState = {
  supabase: SupabaseClient<Database> | null,
	userId: string,
	messages: Messages[],
}

type Messages = Database['public']['Tables']['messages']['Row']

export const messagesState = atom({
  key: "messagesState", 
  default: {
		supabase: null,
		userId: '',
		messages: [],
  } as MessagesState,
})

export const messageLoadingState = atom({
	key: "messageLoadingState",
	default: {
		loading: false,
		error: ''
	}
})