import { atom } from "recoil"
import { User } from '@supabase/auth-helpers-react'
import { Database } from "@/lib"

type UserState = {
  user?: User,
  id?: string,
  email?: string,
  app_settings_json?: any,
  last_poll_time?: number | null,
  subscription?: string | null,
  integration_id?: string | null,
  key_id?: string,
  first_name?: string | null,
  last_name?: string | null,
  onboarded?: boolean | null,
  org_id?: string | null,
  org_role?: string | null,
}

type UserOrgState = Database['public']['Tables']['users']['Row'] & { onlineAt?: string }

export const userState = atom({
  key: "userState", 
  default: {
  } as UserState
})

export const userOrgState = atom({
  key: "userOrgState",
  default: [] as UserOrgState[]
})