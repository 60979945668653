import { SupabaseClient } from '@supabase/supabase-js'
import fetch from 'node-fetch'
import { FollowUpBoss } from "./followUpBoss"
import { checkPhoneEmail } from "../checkPhone"
import { parseNote } from "../parseNote"
import { Database } from '../database.types'

const getSenderDetails = async (noteData: any, supabaseAdmin: SupabaseClient<Database>, integration: Database['public']['Tables']['user_integrations']['Row'], override?: string) => {
  const { personId, body: noteBody, createdById, actionPlanId } = noteData
  if (!integration.data) throw new Error('Integration Data Not Found @ getSenderDetails')
  const fub = new FollowUpBoss(integration.data['api_key'])

  // If Override found, use override name/email to look up the user table
  if (override) {
    console.log('Override: ', override)
    const { data, error } = await supabaseAdmin.from('users').select('id').eq('org_id', integration.org_id).or(`full_name.eq."${override}", email.eq."${override}"`).single()
    if (error) throw error 
    return data.id
  }

  // If ActionPlan exists, we should check the DB. If doesn't exist, pull the data from FUB and push to the DB
  else if (actionPlanId) {
    const { data, error } = await supabaseAdmin.from('universal_mapping').select('*').eq('int_id', integration.id).eq('mapping_type', 'actionPlanId_to_automataUserId').eq('from_id', actionPlanId).single()
    if (error) {
      const actionPlanData = await fub.getData(`actionPlans/${actionPlanId}`)
      const { createdById: actionPlanCreatedById } = actionPlanData
      const { data: userData, error } = await supabaseAdmin.from('users').select('id').eq('external_id', actionPlanCreatedById).eq('org_id', integration.org_id).single()
      if (error) throw error
      const { error: upsertError } = await supabaseAdmin.from('universal_mapping').insert({
        int_id: integration.id,
        mapping_type: 'actionPlanId_to_automataUserId',
        from_id: actionPlanId,
        to_id: userData.id,
      })
      return userData.id
    } else return data.to_id
  }

  // If ActionPlan does not exist, look up user table based on external ID and org ID. Org ID will come from int data
  else {
    const { data, error } = await supabaseAdmin.from('users').select('id').eq('external_id', createdById).eq('org_id', integration.org_id).single()
    if (error) throw error
    return data.id
  }
}

export const fubOrgNoteCreated = async (integration: Database['public']['Tables']['user_integrations']['Row'], userId: string, data: any, supabaseAdmin: SupabaseClient<Database>) => {
  if (!integration.data) return
  const fub = new FollowUpBoss(integration.data['api_key'])

  // Check validity of API key
  const validity = await fub.isApiKeyValid()
  if (!validity) throw new Error(`${userId}'s FUB API Key ${integration.data['api_key']} is invalid`)

  // Get note data based on resource ID
  const noteId = data.resourceIds[0]
  const noteData = await fub.getData(`notes/${noteId}`)
  const { personId, body: noteBody, createdById, actionPlanId } = noteData
  if (!personId) throw new Error(`Person Id missing in note: ${JSON.stringify(noteData)}`)
  if (!noteBody) throw new Error(`Note Body missing in note: ${JSON.stringify(noteData)}`)

  // Get contact data based on person ID
  const personResponseBody = await fub.getData(`people/${personId}`)

  // Parse note body into: protocol, message, and phone label/email
  // TODO: Parse note needs to have a sender override mechanism
  const { error: ParseError, protocol, label, count, message, schedule, overrideSender } = parseNote(noteBody)
  if (ParseError) return { data: null, error: ParseError }
  if (!protocol) return { data: null, error: 'Missing protocol' }

  // Parse contact data to obtain number/email
  let contact
  if (label) {
    contact = label === 'email' 
      ? personResponseBody.emails[count ? count - 1 : 0].value
      : personResponseBody.phones.filter((e: any) => e.type === label)[count ? count - 1 : 0]?.value
  } else {
    contact = personResponseBody.phones[0].value ?? personResponseBody.emails[0].value
  }
  if (!contact) throw new Error(`Contact not found. personId: ${personId}. Label: ${label}. Count: ${count}`)

  const { phoneNumber, error: checkError, isEmail } = checkPhoneEmail(contact.indexOf('@') < 0 && contact.indexOf('+') < 0 ? `+1${contact}`: contact)
  if (checkError) throw new Error(`Bad phone number: ${contact}`)

  // Log number/email <> contact id mapping
  const { error: error3 } = await supabaseAdmin.from('user_contact_mapping').upsert({ 
    user_id: userId,
    integration_id: integration.id,
    contact: phoneNumber,
    contact_id: personId,
    messaged: true
  }, { onConflict: 'user_id,contact,integration_id'})
  if (error3) throw new Error(`Log contact mapping failed: ${JSON.stringify(error3)}`)

  // Get sender user ID
  const senderUserId = await getSenderDetails(noteData, supabaseAdmin, integration, overrideSender)

  // Post message
  const { data: keyData, error: error4 } = await supabaseAdmin.from('keys').select('*').filter('status','eq','active').filter('user_id', 'eq', senderUserId)
  if (error4 || !keyData[0].id) throw new Error(`Unable to get key for ${senderUserId}`)
  const postMsg = await fetch(`https://api.getautomata.app/api/send/${keyData[0].id}/${protocol}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      "phoneNumber": phoneNumber, 
      message,
      integration: 'Follow Up Boss',
      schedule,
      noteId
    }),
  })
  const postMsgResult = await postMsg.json()
  if (postMsg.status !== 200) throw new Error('Message post failed')
  return { data: postMsgResult, error: null}
  
}