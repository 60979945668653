import { createContext, useContext, useEffect, useState } from "react";
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { useRecoilState } from "recoil";
import { getUniqueListBy } from "@/lib"
import { Database } from '@/lib/database.types'
import * as Atoms from '@/atoms'

type Context = {
	messages?: Database['public']['Tables']['messages']['Row'][],
  loadingInitial?: boolean,
  error?: string,
}

const AppContext = createContext<Context>({});

const AppContextProvider = ({ children }: { children: any}) => {

  const [messages, setMessages] = useState<Database['public']['Tables']['messages']['Row'][]>([])
  const [error, setError] = useState("")
  const [loadingInitial, setLoadingInitial] = useState(true)
  const [userAtom, setUserAtom] = useRecoilState(Atoms.userState)
  const supabase = useSupabaseClient<Database>()

  useEffect(() => {
    if (userAtom?.id) {
			const channels = supabase.getChannels()
			console.log(`Retrying subscription`)
			if (channels.length) supabase.removeAllChannels().then((value) => {
				console.log(`Removed all channels. Status: ${value}`)
        setLoadingInitial(true)
				if (userAtom.id) getMessagesAndSubscribe(userAtom.id)
			})
			else {
        setLoadingInitial(true)
        getMessagesAndSubscribe(userAtom.id)
      }
    }
  }, [userAtom]);

  const getInitialMessages = async (userId: string) => {
    if (!messages.filter(m => m.user_id === userId).length) {
      const { data, error } = await supabase
        .from('messages')
        .select()
        .eq('user_id', userAtom?.id)
        .not('is_complete', 'is', true)
        .order('scheduled_send', { ascending: true })
      // console.log(`data`, data);
      if (error) {
        setError(error.message);
        supabase.removeChannel(supabase.channel("public:messages"));
        return;
      }
      setMessages(data);
    }
  };

  const getMessagesAndSubscribe = async (userId: string) => {
    console.log(`Adding supabase subscription for user: ${userId}`)
    setError("");
    await getInitialMessages(userId).then(() => {
      setLoadingInitial(false)
      return supabase
      .channel("public:messages")
      .on('postgres_changes', { event: '*', schema: 'public', table: 'messages' }, (payload) => {
        console.log('realtime payload: ', payload);

        if (payload.eventType === 'INSERT') {
          setMessages((prevMessages) => getUniqueListBy([...prevMessages, payload.new], 'id').sort((a, b) => (a.scheduled_send - b.scheduled_send)))
        }

        if (payload.eventType === 'UPDATE') {
          if (payload.new.is_complete) {
            setMessages((prevMessages) => getUniqueListBy([...prevMessages.filter(m => m.id !== payload.new.id)], 'id').sort((a, b) => (a.scheduled_send - b.scheduled_send)))
          }
        }

      })
      .subscribe();
    })
  };

  return (
    <AppContext.Provider
      value={{
				messages,
        loadingInitial,
        error,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppContext as default, AppContextProvider as RealtimeProvider, useAppContext as useRealtimeData };