import { DateTime } from "luxon"

type ParseResult = {
	error?: string,
	schedule?: number,
	protocol?: string,
	label?: string,
	count?: number,
	message?: string,
	overrideSender?: string,
}

const zoneMap: { [x :string]: string } = {
	EDT: 'America/New_York',
	PDT: 'America/Los_Angeles',
	CDT: 'America/Chicago',
	MDT: 'America/Denver',
}

export const parseSchedule = (schedule: string) => {
	let [time, zone] = schedule.substring(2, schedule.length -2).split(' ')
	if (!time) return undefined
	if (time.length === 4) time = '0' + time

	const dateNow = DateTime.now().setZone(zoneMap[zone] ?? zone).toISO()
	if (!dateNow) return undefined
	const newISO = `${dateNow.substring(0,11)}${time}${dateNow.substring(16)}`
	const parsedNewISO = DateTime.fromISO(newISO)

	return (parsedNewISO.valueOf() < DateTime.now().valueOf()) ? parsedNewISO.plus({days: 1}).valueOf() : parsedNewISO.valueOf()
}

export const parseNote = (note: string): ParseResult => {

	// Checks:
	// Check for start sequence
	if (note.substring(0, 2) !== '##') return { error: 'No start sequence' }
	
	const parseByLine = note.split('\n')
	// Check for message
	if (parseByLine.length < 2) return { error: 'No message detected' }

	let [protocol, label, count] = parseByLine[0].substring(2).trim().split(' ')
	let message = parseByLine.slice(1).join('\n')
	let schedule
	let overrideSender

	// Check for scheduling string
	const scheduleString = parseByLine[parseByLine.length - 1].match(/\[\[\d{1,2}:\d\d \w\w\w\]\]/gm) ?? parseByLine[parseByLine.length - 2].match(/\[\[\d{1,2}:\d\d \w\w\w\]\]/gm)
	if (scheduleString) {
		try {
			schedule = parseSchedule(scheduleString[0])
		} catch(e) {
			console.error(e)
		}
	}

	// Check for user override string
	const overRideString = parseByLine[parseByLine.length - 1].match(/(\[\[[\w ]*\]\])|(\[\[[\w@.]*\]\])/gm) ?? parseByLine[parseByLine.length - 2].match(/(\[\[[\w ]*\]\])|(\[\[[\w@.]*\]\])/gm)
	if (overRideString) {
		try {
			overrideSender = overRideString[0].substring(2, overRideString[0].length -2)
		} catch(e) {
			console.error(e)
		}
	}

	if (scheduleString && overRideString) message = parseByLine.slice(1, -2).join('\n')
	else if (scheduleString || overRideString) message = parseByLine.slice(1, -1).join('\n')

  return {
		protocol: protocol?.toLowerCase(),
		label: label?.toLowerCase(),
		count: Number(count) ?? 0,
		message,
		schedule,
		overrideSender,
	}
}