import parsePhoneNumber, { isValidPhoneNumber, CountryCode } from 'libphonenumber-js'
import * as EmailValidator from 'email-validator'


export const checkPhoneEmail = (phone: string, country?: CountryCode) => {
  let phoneNumber = phone
  let error
  let isEmail = phoneNumber.indexOf('@') >= 0
  
  if (phoneNumber.indexOf('@') >= 0) {
    // If email, check email
    if (!EmailValidator.validate(phoneNumber)) {
      error = 'Bad email'
    }
  } else {
    // If phone number, check phone number
    switch (true) {
      case isValidPhoneNumber(phoneNumber):
        phoneNumber = parsePhoneNumber(phoneNumber)?.formatInternational() ?? ''
        break;
      case country && isValidPhoneNumber(phoneNumber, country):
        phoneNumber = parsePhoneNumber(phoneNumber, country)?.formatInternational() ?? ''
        break;
      case isValidPhoneNumber(`+${phoneNumber}`):
        phoneNumber = parsePhoneNumber(`+${phoneNumber}`)?.formatInternational() ?? ''
        break;
      case isValidPhoneNumber(phoneNumber, country ?? 'US'):
        phoneNumber = parsePhoneNumber(phoneNumber, country ?? 'US')?.formatInternational() ?? ''
        break;
      default:
        error = 'Bad phone number';
        break;
    }
  }
  return {
    phoneNumber: phoneNumber.trim().replace(/ /g, ''),
    isEmail,
    error
  }
}