import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from "recoil"
import { SupabaseClient, useSupabaseClient, useUser } from '@supabase/auth-helpers-react'
import { Database } from '@/lib/database.types'
import { getUniqueListBy } from "@/lib/utils"
import * as Atoms from '@/atoms'

const useMessageSync = () => {
  const supabase = useSupabaseClient<Database>()
	const user = useUser()
  const [messagesState, setMessagesState] = useRecoilState(Atoms.messagesState)
  const setLoading = useSetRecoilState(Atoms.messageLoadingState)

  useEffect(() => {
		
    const getInitialMessages = async (supabase: SupabaseClient<Database>, userId: string) => {
			const { data, error } = await supabase
				.from('messages')
				.select()
				.eq('user_id', userId)
				.not('is_complete', 'is', true)
				.order('scheduled_send', { ascending: true })
			// console.log(`data`, data);
			if (error) {
				setLoading({ loading: false, error: error.message })
				supabase.removeChannel(supabase.channel("public:messages"))
				return
			} else setLoading({ loading: false, error: '' })
			setMessagesState((oldValue) => ({
				...oldValue,
				messages: data
			}))
		}

		const getMessagesAndSubscribe = async (supabase: SupabaseClient<Database>, userId: string) => {
			// logMsg(`Adding supabase subscription`)
			setLoading({ loading: true, error: '' })
			await getInitialMessages(supabase, userId)
			return supabase
				.channel("public:messages")
				.on('postgres_changes', { event: '*', schema: 'public', table: 'messages' }, (payload) => {
					console.log('realtime payload: ', payload)
	
					if (payload.eventType === 'INSERT') {
						setMessagesState((oldValue) => ({
							...oldValue,
							messages: getUniqueListBy([...oldValue.messages, payload.new], 'id').sort((a, b) => (a.scheduled_send - b.scheduled_send))
						}))
					}
	
					if (payload.eventType === 'UPDATE') {
						if (payload.new.is_complete) {
							setMessagesState((oldValue) => ({
								...oldValue,
								messages: getUniqueListBy([...oldValue.messages.filter(m => m.id !== payload.new.id)], 'id').sort((a, b) => (a.scheduled_send - b.scheduled_send))
							}))
						}
					}
				})
				.subscribe()
		}

		if (supabase && user?.id) {
			if (!supabase.getChannels().length) {
				getMessagesAndSubscribe(supabase, user.id)
			}
		}

		return () => {
			console.log("Remove supabase subscription by useEffect unmount")
			supabase.removeAllChannels().then(() => console.log("Channels removed"))
		}
  }, [supabase, user])

  return
}

export default useMessageSync