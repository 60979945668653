import { atom, DefaultValue, selector } from "recoil"

export type Integrations = {
  id?: string,
  name?: string| null,
  user_id?: string| null,
  data?: any,
  created_at?: string | null,
  active?: boolean | null,
}

export const integrationsState = atom({
  key: "integrationsState", 
  default: {} as Integrations[],
})