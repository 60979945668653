export function getUniqueListBy(arr: any[], key: string) {
	return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const toProperCase = (text: string) => {
	return text.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const throwLogError = (type: string, error: any) => {
	console.log(`${type}: `, error)
	throw new Error(`${type}: ${JSON.stringify(error)}`)
}